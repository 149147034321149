<template>
    <div>
        <FilterModal
            :isLoading="isLoading"
            @reset="resetFilter"
            @filter="getFilterData"
        >
            <template v-slot:filters>
                <TitleTypeAccountFilter
                    ref="titleTypeAccountFilter"
                    v-model:titleType="filterPayload.titleType"
                    v-model:manCodes="filterPayload.manCodes"
                    :hasSelectedALL="true"
                />
                <Dropdown
                    title="通知類型"
                    name="notifyGroupType"
                    type="text"
                    :options="transformAPIFormat"
                    v-model="filterPayload.notifyGroupType"
                />
                <Dropdown
                    title="通知狀態"
                    name="notifyState"
                    :required="true"
                    type="text"
                    :options="[
                        { label: '全部', value: null },
                        { label: '未完成', value: false },
                        { label: '已完成', value: true }
                    ]"
                    v-model="filterPayload.isChecked"
                />
                <DateRangeTextbox
                    v-if="mode === 'self'"
                    :hasLimit="true"
                    :required="currentNotifyStatus"
                    :limitDays="currentNotifyStatus ? { year: 1 } : {}"
                    max="today"
                    title="通知日"
                    :tipObj="dateFilterTips"
                    v-model:startDate="filterPayload.notifyDateStart"
                    v-model:endDate="filterPayload.notifyDateEnd"
                />
            </template>
        </FilterModal>
    </div>
</template>
<script>
import FilterModal from '@/containers/FilterModal.vue'
import Dropdown from '@/components/Dropdown.vue'
import DateRangeTextbox from '../../components/DateRangeTextbox.vue'
import TitleTypeAccountFilter from '@/containers/TitleTypeAccountFilter.vue'
import { notificationFilterTitls } from '@/assets/javascripts/tia/setting'
import { getNotificationCodesAPI } from '@/assets/javascripts/api'
import filterDefault from '@/assets/json/filterDefault.json'
export default {
    name: 'NotificationFilter',
    emits: [
        'show-data-table',
        'update:filterPayloadProp',
        'update:filterLogProp'
    ],
    props: {
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {
                    manCode: ''
                }
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'self'
        }
    },
    components: {
        FilterModal,
        Dropdown,
        TitleTypeAccountFilter,
        DateRangeTextbox
    },
    methods: {
        resetFilter: function () {
            //從核心資料庫取得此次使用者識別
            this.filterPayload = {
                manCodes: this.filterPayload?.manCodes || '',
                isOrg: this.mode === 'self' ? false : true,
                ...filterDefault.notification
            }
            this.$refs.titleTypeAccountFilter.resetFilter()
            // across filter
            this.filterPayload = this.$getStorePayload(this.filterPayload)
            this.$emit('update:filterPayloadProp', this.filterPayload)
        },
        getFilterData: function () {
            if (
                !this.$isSameValue(
                    this.perviousFilterPayload,
                    this.filterPayload
                )
            ) {
                this.perviousFilterPayload = this.$cloneDeepValue(
                    this.filterPayload
                )
                this.$emit('show-data-table')
            }
            this.hasCrossFilter = false
        },
        getDropdownOption: async function () {
            this.$showLoading()
            try {
                const response = await getNotificationCodesAPI()
                this.dropdownOptions.notifyGroupType =
                    response.data.notifyGroupType
                this.dropdownOptions.notifyGroupType.unshift({
                    value: '全部',
                    key: 0
                })
            } catch (error) {
                this.dropdownOptions = {}
                this.$showErrorMessage(
                    '載入時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            } finally {
                this.$hideLoading()
            }
        }
    },
    computed: {
        transformAPIFormat: function () {
            return this.dropdownOptions?.notifyGroupType?.map((typeData) => {
                return { label: typeData.value, value: typeData.key }
            })
        },
        currentNotifyStatus: function () {
            let isNotifyDone
            if (this.filterPayload.isChecked == false) {
                isNotifyDone = false
                return isNotifyDone
            } else {
                isNotifyDone = true
                return isNotifyDone
            }
        },
        dateFilterTips: function () {
            const tip = this.currentNotifyStatus
                ? `為提供較佳的效能體驗，供查詢的資料區間僅限12個月`
                : ''
            return { start: tip, end: tip }
        }
    },
    watch: {
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        },
        mode: function (newVal) {
            if (newVal === 'self') this.filterPayload.isOrg = false
            else this.filterPayload.isOrg = true
        },
        currentNotifyStatus: {
            handler: function (status) {
                if (status === true) {
                    this.filterPayload.notifyDateStart = this.$formatDate(
                        this.$getDate(-365)
                    )
                    this.filterPayload.notifyDateEnd = this.$formatDate(
                        this.$getDate()
                    )
                } else {
                    this.filterPayload.notifyDateStart = null
                    this.filterPayload.notifyDateEnd = null
                }
            }
        }
    },
    data() {
        return {
            filterTitles: notificationFilterTitls,
            filterPayload: this.filterPayloadProp,
            perviousFilterPayload: {},
            dropdownOptions: { notifyGroupType: [] },
            filterLog: this.filterLogProp,
            mType: '',
            hasCrossFilter: this.$hasCrossFilter()
        }
    },
    async mounted() {
        this.getDropdownOption()
        this.resetFilter()
        if (this.hasCrossFilter) await this.getFilterData()
    }
}
</script>

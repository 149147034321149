<template>
    <Textbox
        :name="startName"
        type="date"
        :max="max"
        :min="min"
        :required="required"
        :disabled="isDisabled"
        :title="title + '(起)'"
        :onlyMonth="isOnlyMonth"
        :onlyDate="isOnlyDate"
        v-model="startDateValue"
        :tips="tipObj.start"
        ref="startRef"
    />
    <Textbox
        :name="endName"
        type="date"
        :max="getMaxEndDate(endDateValue, startDateValue)"
        :min="minEndDate"
        :required="required"
        :disabled="isDisabled"
        :title="title + '(迄)'"
        :onlyMonth="isOnlyMonth"
        :onlyDate="isOnlyDate"
        :isMonthLastDay="true"
        :is="isOnlyDate"
        v-model="endDateValue"
        :tips="tipObj.end"
        ref="endRef"
    />
</template>

<script>
import Textbox from '@/components/Textbox.vue'

export default {
    name: 'DateRangeTextbox',
    components: { Textbox },
    emits: ['update:startDate', 'update:endDate'],
    props: {
        title: { type: String, default: '' },

        startName: {
            type: String,
            default: ''
        },
        endName: {
            type: String,
            default: ''
        },
        startDate: {
            type: String,
            default: ''
        },
        endDate: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        max: {},
        min: {},
        hasLimit: {
            type: Boolean,
            default: false
        },
        limitDays: {
            type: Object,
            default: function () {
                return {
                    year: 0,
                    month: 0,
                    day: 0
                }
            }
        },
        isOnlyMonth: {
            type: Boolean,
            default: false
        },
        isOnlyDate: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        tipObj: {
            type: Object,
            default: function () {
                return {
                    start: '',
                    end: ''
                }
            }
        }
    },
    methods: {
        getMaxEndDate: function (end, start) {
            if (this.hasLimit) {
                const endDate = this.$getDate(0, 0, 0, end)
                const limitYear = this.limitDays?.year ?? 0
                const limitMonth = this.limitDays?.month ?? 0
                const limitDay = this.limitDays?.day ?? 0
                const hasLimitDay = !!limitYear || !!limitMonth || !!limitDay

                const translLimitDay = limitDay === 0 ? 1 : limitDay + 1
                const lastDate = this.isOnlyMonth
                    ? this.$getDate(
                          -1,
                          0,
                          0,
                          this.$getDate(
                              0,
                              12 * limitYear + limitMonth + 1,
                              0,
                              start
                          )
                      )
                    : this.$getDate(
                          -1,
                          0,
                          0,
                          this.$getDate(
                              translLimitDay,
                              limitMonth,
                              limitYear,
                              start
                          )
                      )
                const today = this.$getDate()
                return (lastDate > endDate && lastDate > today) || !hasLimitDay
                    ? 'today'
                    : this.$formatDate(lastDate)
            } else {
                return this.max
            }
        },
        setEffectRequired: function () {
            if (!this.required) {
                this.$nextTick(() => {
                    const startRef = this.$refs.startRef.$el
                    const endRef = this.$refs.endRef.$el
                    const startEl = startRef?.querySelector(
                        'input:not([type=hidden])'
                    )
                    const endEl = endRef?.querySelector(
                        'input:not([type=hidden])'
                    )
                    const startValue = this.startDateValue
                    const endValue = this.endDateValue
                    if (this.startDateValue || this.endDateValue) {
                        this.$setRequiredText(
                            startEl,
                            startValue,
                            `請填寫這個欄位，或清除${this.title}(迄)`
                        )
                        this.$setRequiredText(
                            endEl,
                            endValue,
                            `請填寫這個欄位，或清除${this.title}(起)`
                        )
                    } else {
                        startEl.setCustomValidity('')
                        endEl.setCustomValidity('')
                    }
                })
            }
        }
    },
    computed: {
        startDateValue: {
            get() {
                return this.startDate
            },
            set(val) {
                let value = val
                if (val && this.isOnlyMonth && val?.length === 6)
                    value = val + '-01'
                this.$emit('update:startDate', value)
            }
        },
        endDateValue: {
            get() {
                return this.endDate
            },
            set(val) {
                this.$emit('update:endDate', val)
            }
        },
        minEndDate: function () {
            if (this.hasLimit) {
                return this.startDateValue
            } else {
                return this.min
            }
        }
    },
    watch: {
        startDateValue: {
            handler() {
                this.setEffectRequired()
            }
        },
        endDateValue: {
            handler() {
                this.setEffectRequired()
            }
        },
        tipObj: {
            handler() {
                this.$reloadTips()
            },
            deep: true
        }
    }
}
</script>

<style></style>
